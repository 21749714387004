<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12" v-if="step === 1">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Dados do evento</h4>
            <div class="row">
              <div class="col-sm-5">
                <div class="form-group mb-3">
                  <label for="name">
                    Nome do evento
                    <span id="tooltip-name-event" class="link font-size-16"><i class="bx bx-info-circle"></i></span>
                    <b-tooltip target="tooltip-name-event" triggers="hover">
                      Informe um nome para o seu evento
                    </b-tooltip>
                  </label>
                  <input
                    id="name"
                    v-model="event.name"
                    name="name"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.event.name.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.event.name.required"
                    class="invalid-feedback"
                  >
                    O campo nome é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group mb-3">
                  <label for="event_code">
                    Código evento
                    <span id="tooltip-eventcode-event" class="link font-size-16"><i class="bx bx-info-circle"></i></span>
                    <b-tooltip target="tooltip-eventcode-event" triggers="hover">
                      Informe um codigo/nome único para esse evento
                    </b-tooltip>
                  </label>
                  <input
                    id="event_code"
                    v-model="event.event_code"
                    name="event_code"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.event.event_code.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.event.event_code.required"
                    class="invalid-feedback"
                  >
                    O campo código é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group mb-3">
                  <label for="plan_id">
                    Usuários simultâneos
                    <span id="tooltip-users-event" class="link font-size-16"><i class="bx bx-info-circle"></i></span>
                    <b-tooltip target="tooltip-users-event" triggers="hover">
                      Escolha quantidade de usuários simultâneos
                    </b-tooltip>
                  </label>
                  <select
                    class="form-select"
                    name="plan_id"
                    id="plan_id"
                    v-model="event.plan_id"
                    :class="{
                      'is-invalid': submitted && $v.event.plan_id.$error,
                    }">
                    <option v-for="(plan, i) in plans" :key="i" :value="plan.id">{{ plan.name }}</option>
                  </select>
                  <div
                    v-if="submitted && !$v.event.plan_id.required"
                    class="invalid-feedback"
                  >
                  O campo é obrigatório
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group mb-3">
                  <label for="description">
                    Descrição do evento
                    <span id="tooltip-description-event" class="link font-size-16"><i class="bx bx-info-circle"></i></span>
                    <b-tooltip target="tooltip-description-event" triggers="hover">
                      Breve descrição sobre o evento
                    </b-tooltip>
                  </label>
                  <textarea
                    id="description"
                    v-model="event.description"
                    name="description"
                    type="text"
                    rows="4"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.event.description.$error,
                    }"
                  >
                  </textarea>
                  <div
                    v-if="submitted && !$v.event.description.required"
                    class="invalid-feedback"
                  >
                    O campo descrição é obrigatório
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <div class="form-group mb-3">
                  <label for="title">Data/Hora de início</label>
                  <date-picker
                    v-model="event.start_date"
                    type="datetime"
                    :first-day-of-week="1"
                    lang="pt-br"
                    format="DD/MM/YYYY HH:mm"
                    :class="{
                      'is-invalid': submitted && $v.event.start_date.$error,
                    }"
                  >
                  </date-picker>
                  <div
                    v-if="submitted && !$v.event.start_date.required"
                    class="invalid-feedback"
                  >
                  O campo Abertura é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group mb-3">
                  <label for="title">Data/Hora de término</label>
                  <date-picker
                    v-model="event.end_date"
                    type="datetime"
                    :first-day-of-week="1"
                    lang="pt-br"
                    format="DD/MM/YYYY HH:mm"
                    :class="{
                      'is-invalid': submitted && $v.event.end_date.$error,
                    }"
                  >
                  </date-picker>
                  <div
                    v-if="submitted && !$v.event.end_date.required"
                    class="invalid-feedback"
                  >
                  O campo Encerramento é obrigatório
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Dados do administrador</h4>
            <div class="row">
              <div class="col-sm-5">
                <div class="form-group mb-3">
                  <label for="username">
                    Nome do usuário
                    <span id="tooltip-name-user" class="link font-size-16"><i class="bx bx-info-circle"></i></span>
                    <b-tooltip target="tooltip-name-user" triggers="hover">
                      Nome do usuário administardor
                    </b-tooltip>
                  </label>
                  <input
                    id="username"
                    v-model="event.user.name"
                    name="username"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.event.user.name.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.event.user.name.required"
                    class="invalid-feedback"
                  >
                    O campo nome é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label for="useremail">
                    E-mail
                    <span id="tooltip-email-user" class="link font-size-16"><i class="bx bx-info-circle"></i></span>
                    <b-tooltip target="tooltip-email-user" triggers="hover">
                      Informe um e-mail válido para utilizar no acesso ao painel
                    </b-tooltip>
                  </label>
                  <input
                    id="useremail"
                    v-model="event.user.email"
                    name="useremail"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.event.user.email.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.event.user.email.required"
                    class="invalid-feedback"
                  >
                    O campo e-mail é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="senha">
                    Senha
                    <span id="tooltip-senha-user" class="link font-size-16"><i class="bx bx-info-circle"></i></span>
                    <b-tooltip target="tooltip-senha-user" triggers="hover">
                      Senha para acesso ao painel
                    </b-tooltip>
                  </label>
                  <input
                    id="senha"
                    v-model="event.user.password"
                    name="senha"
                    type="password"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.event.user.password.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.event.user.password.required"
                    class="invalid-feedback"
                  >
                    O campo senha é obrigatório
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="float-end">
              <button type="buton" @click="cancelar()" class="btn btn-dark me-1">
                Cancelar
              </button>
              <button type="button" @click="nextStep(3)" class="btn btn-primary me-1">
                Próximo <i class="bx bx-right-arrow-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12" v-if="step === 2">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Domínio do evento</h4>
            <p>O domínio é o endereço de acesso do evento pela internet, e é por ele onde os participantes acessam a plataforma. Escolha uma opção:</p>
            <div class="row">
              <div class="col-md-5 offset-md-1">
                <div class="domain-eventfy" @click="selectDomain(1)" :class="{ 'active': domain.type === 1}">
                  <img src="@/assets/images/eventfy-black.png" alt height="40" width="152" class="mb-4"/>
                  <h4 class="card-title mb-4 mt-1">Domínio {{ companyDomain.domain }}</h4>
                  <p>O endereço do seu evento com a nossa identidade</p>
                </div>
              </div>
              <div class="col-md-5">
                <div class="domain-eventfy" @click="selectDomain(2)" :class="{ 'active': domain.type === 2}">
                  <img src="@/assets/images/www.png" alt height="65" width="65" />
                  <h4 class="card-title mb-4 mt-2">Dominio personalizado</h4>
                  <p>O endereço do seu evento personalizado com a sua identidade</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="float-end">
              <button type="buton" @click="step = 1" class="btn btn-dark me-1">
                Voltar
              </button>
              <button type="button" @click="nextStepDomain()" class="btn btn-primary me-1">
                Próximo <i class="bx bx-right-arrow-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12" v-if="step === 3">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Domínio {{ companyDomain.domain }}</h4>
            <p v-if="domain.type === 1">Insira o nome/endereço do seu evento</p>
            <!-- <p v-if="domain.type === 2">Insira o endereço do seu dominio</p> -->
            <div v-if="domain.type === 1">
              <div class="row">
                <div class="col-md-5">
                  <b-input-group prepend="https://" :append="`.${companyDomain.domain}`" class="mb-3">
                    <b-input
                      :disabled="domain.available"
                      type="email"
                      id="inlineFormemail2"
                      class="form-control"
                      placeholder="meuevento"
                      v-model="domain.name"
                      :class="{
                        'is-invalid': submitted && $v.domain.name.$error,
                      }"
                    ></b-input>
                  </b-input-group>
                </div>
                <div class="col-md-6">
                  <button type="button" @click="checkSubDomain()" class="btn btn-success me-1 mb-2">
                    Checar disponibilidade
                  </button>
                  <button type="button" @click="cleanSubDomain()" class="btn btn-danger me-1 mb-2">
                    Limpar
                  </button>
                </div>
              </div>
              <div class="row mt-4" v-if="domain.available && domain.name">
                <div class="col-md-12">
                  <b-alert variant="success" show class="text-center">
                    <h3 class="alert-heading">{{ message_check_domain }}</h3>
                    <p class="font-size-16">
                      <b>Tudo certo!</b> O endereço do seu evento será:
                    </p>
                    <p class="mb-0 font-size-20">
                    https://{{ domain.name }}.{{ companyDomain.domain }}
                    </p>
                  </b-alert>
                </div>
              </div>
            </div>
            <div v-if="domain.type === 2">
              <div class="row mb-4">
                <div class="col-md-5">
                  <b-input-group prepend="https://" class="mb-3">
                    <b-input
                      :disabled="domain.available"
                      type="email"
                      id="inlineFormemail2"
                      class="form-control"
                      placeholder="meuevento.com.br"
                      v-model="domain.name"
                      :class="{
                        'is-invalid': submitted && $v.event.user.password.$error,
                      }"
                    ></b-input>
                  </b-input-group>
                </div>
                <!-- <div class="col-md-6">
                  <button type="button" @click="checkSubDomain()" class="btn btn-success mr-1 mb-2">
                    Checar disponibilidade
                  </button>
                  <button type="button" @click="cleanSubDomain()" class="btn btn-danger mr-1 mb-2">
                    Limpar
                  </button>
                </div> -->
              </div>
              <h4 class="card-title mb-2">Instruções de apontamento</h4>
              <p class="text-inline">Substitua os nameservers do seu dominio para os nameservers abaixo:</p>
              <div class="row">
                <div class="col-md-5">
                  <div class="form-group">
                    <label for="useremail">
                      Nameserver 1
                      <span id="tooltip-ns1" class="link font-size-16"><i class="bx bx-info-circle"></i></span>
                      <b-tooltip target="tooltip-ns1" triggers="hover">
                        ..
                      </b-tooltip>
                    </label>
                    <div class="input-group">
                      <input
                        v-on:focus="$event.target.select()"
                        ref="nameserver1"
                        id="nameserver1"
                        name="nameserver1"
                        value="sasha.ns.cloudflare.com"
                        type="text"
                        class="form-control"
                        readonly
                      />
                      <button
                        class="btn btn-dark"
                        type="button"
                        id="nameserver1"
                        @click="copyNS('nameserver1')"
                      >
                        Copiar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <div class="form-group">
                    <label for="useremail">
                      Nameserver 2
                      <span id="tooltip-ns2" class="link font-size-16"><i class="bx bx-info-circle"></i></span>
                      <b-tooltip target="tooltip-ns2" triggers="hover">
                        ..
                      </b-tooltip>
                    </label>
                    <div class="input-group">
                      <input
                        v-on:focus="$event.target.select()"
                        ref="nameserver2"
                        id="nameserver2"
                        name="nameserver2"
                        value="nora.ns.cloudflare.com"
                        type="text"
                        class="form-control"
                        readonly
                      />
                      <button
                        class="btn btn-dark"
                        type="button"
                        id="nameserver2"
                        @click="copyNS('nameserver2')"
                      >
                        Copiar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-4" v-if="domain.available && domain.name">
                <div class="col-md-12">
                  <b-alert variant="success" show class="text-center">
                    <h3 class="alert-heading">{{ message_check_domain }}</h3>
                    <p class="font-size-16">
                      <b>Tudo certo!</b> O endereço do seu evento será:
                    </p>
                    <p class="mb-0 font-size-20">
                    https://{{ domain.name }}.eventfy.live
                    </p>
                  </b-alert>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="float-end">
              <button type="buton" @click="step = 2; domain.type = ''" class="btn btn-dark me-1">
                Voltar
              </button>
              <button type="button" @click="createEvent()" class="btn btn-primary me-1" :disabled="!domain.available">
                Concluir
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </Layout>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/pt-br'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import service from './services'

export default {
  components: {
    DatePicker,
    Layout,
    PageHeader
  },
  name: 'form-event',
  page: {
    title: 'Novo Evento',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      step: 1,
      message_check_domain: '',
      plan_id: '',
      plans: [],
      event: {
        id: '',
        name: '',
        status: 0,
        description: '',
        user: {
          password: '',
          name: '',
          email: ''
        }
      },
      domain: {
        type: '',
        name: '',
        available: false
      },
      companyDomain: {},
      submitted: false,
      title: 'Criar Evento',
      items: [{
        text: 'Meus Eventos',
        href: '/events'
      },
      {
        text: 'Novo Evento',
        active: true
      }
      ]
    }
  },
  validations: {
    event: {
      name: { required },
      event_code: { required },
      description: { required },
      start_date: { required },
      plan_id: { required },
      end_date: { required },
      user: {
        name: { required },
        email: { required },
        password: { required }
      }
    },
    domain: {
      type: { required },
      name: { required }
    }
  },
  mounted () {
    this.loadItem()
    this.getPlans()
    this.getMyDomain()
  },
  computed: {
    ...mapState('events', { data: 'event' }),
    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapActions('events', {
      fetch: 'getEvent',
      checkDNS: 'checkDomainDNS',
      postData: 'save'
    }),
    getPlans () {
      service.listPlans({}).then(({ data }) => {
        this.plans = data.data
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
      })
    },
    getMyDomain () {
      service.domain({}).then(({ data }) => {
        this.companyDomain = data
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
      })
    },
    copyNS (ref) {
      this.$refs[`${ref}`].focus()
      document.execCommand('copy')
      successMessage('Copiado!')
    },
    cancelar () {
      this.$router.push({ name: 'events' })
    },
    nextStep (step) {
      if (step) {
        this.submitted = true
        if (this.step === 1) {
          this.$v.event.$touch()
          if (this.$v.event.$invalid) {
            window.scroll(0, 0)
            return
          }
        }
        this.step = step
        this.domain.type = 1
        return
      }
      this.step = 1
    },
    nextStepDomain () {
      if (this.domain.type) {
        this.step = 3
        return
      }
      errorMessage('Alerta: Você precisa selecionar o tipo de domínio para o seu evento.')
    },
    selectDomain (type) {
      this.domain.type = type
    },
    loadItem () {
      if (this.$route.params.id !== undefined) {
        let loader = this.$loading.show()
        this.items[1].text = 'Editar Evento'
        this.fetch(this.$route.params.id)
          .then(() => {
            this.event = this.data
            this.event.start_date = this.$moment(this.event.start_date, 'YYYY-MM-DD HH:mm').toDate()
            this.event.end_date = this.$moment(this.event.end_date, 'YYYY-MM-DD HH:mm').toDate()
            if (this.data.users) {
              this.event.user = this.data.users.data
            }
          }).then(() => {
            loader.hide()
          })
      }
      let loader = this.$loading.show()
      loader.hide()
    },
    cleanSubDomain () {
      this.domain.available = false
      this.domain.name = ''
    },
    async checkSubDomain () {
      let loader = this.$loading.show()
      if (!this.domain.name) {
        loader.hide()
        errorMessage('Alerta: Preencha todos os campos')
        this.domain.available = false
        return
      }
      await this.checkDNS(this.domain).then(res => {
        this.domain.available = true
        this.message_check_domain = 'Domínio disponível'
        loader.hide()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    },
    async createEvent () {
      this.submitted = true
      this.$v.event.$touch()
      if (this.$v.event.$invalid) {
        window.scroll(0, 0)
        return
      }

      let loader = this.$loading.show()
      this.event.domain = { ...this.domain }
      this.event.start_date = this.$moment(this.event.start_date).format('YYYY-MM-DD HH:mm:ss')
      this.event.end_date = this.$moment(this.event.end_date).format('YYYY-MM-DD HH:mm:ss')

      await this.postData(this.event).then((data) => {
        let message = 'Sucesso: Ação registrada.'
        let delay = 2500
        successMessage(message, delay).then(() => {
          this.$router.push({
            name: 'event-in-production',
            params: {
              id: this.data.id
            }
          })
          loader.hide()
        })
      }).catch((err) => {
        console.log(err)
        errorMessage('Alerta: ' + err.message)
        this.submitted = false
        loader.hide()
      })
    }
  },
  watch: {
    'domain.name' (value) {
      this.domain.name = value.replace(/[^a-zA-Z0-9]/g, '')
    },
    'event.event_code' (value) {
      this.event.event_code = value.replace(/[^a-zA-Z0-9]/g, '')
    }
  }
}
</script>

<style scoped>
  .domain-eventfy {
    padding: 20px;
    gap: 1rem;
    text-align: center;
    padding-top: 15%;
    max-height: 35rem;
    height: 23rem;
    background: #FFFFFF;
    border: 1px solid #45506F;
    box-sizing: border-box;
    box-shadow: 0px 3px 8px 4px rgba(0, 0, 0, 0.01);
    border-radius: 7px;
    margin: 2rem;
    cursor: pointer;
  }
  .domain-eventfy:hover {
    background: #eaefff;
  }
  .active {
    background: #eaefff;
  }
</style>
